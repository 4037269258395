import * as React from "react"
import Layout from "../components/layout"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div className="flex-min-height-100vh text-color-black pos-rel container">
        <div className="center">
        <h1 className="headline-xxl padding-top-bottom-120">This page does not exist</h1>
      <h2 className="subheading-l">Something weird happened...</h2>
      <p className="body-text-l">
        I don't have any answers for you. Maybe try going back to another page. :(
      </p>
      </div>
    </div>
    </Layout>
  )
}

export default NotFoundPage
